@import "main.css";
.navbar-nav > .dropdown {
  display: none;
}

.px-4.py-5.text-center.cta_banner.bg-sixthColor.position-relative > .background-image-div-opacity {
background-attachment: fixed;
}

.px-2.px-lg-4.py-2.bg-sixthColor {
    display: none;
}